import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {showAlert} from "../../common/sweetalert/sweetalert";
import axios from "axios";
import {currencyConverter, serverLink} from "../../url";
import {isPossiblePhoneNumber} from 'libphonenumber-js'
const randomToken = require('random-token');

const CourseDetail = (props) => {
    const [error, setError] = useState(null);
    const [phoneNumberMessage, setPhoneNumberMessage] = useState(null);
    const navigate = useNavigate();
    const {slug} = useParams();
    const location = useLocation();
    if (slug === "") navigate("/our-programs");
    const [courseData, setCourseData] = useState(typeof props.HomeReport.course !== 'undefined' ? props.HomeReport.course.filter(i => i.Slug === slug) : [])
    const [captcha, setCaptcha] = useState(randomToken(6));

    const [createItem, setCreateItem] = useState({
        firstName: "",
        middleName: "",
        surname: "",
        emailAddress: "",
        phoneNumber: "",
        highestQualification: "",
        employmentStatus: "",
        areaOfTraining: "",
        organisation: "",
        jobLevel: "",
        appCaptcha: "",
    });

    const isValidEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const isPhoneNumberValid = (phoneNumber) => {
        const isValid = isPossiblePhoneNumber(phoneNumber)

        if (isValid) {
            setPhoneNumberMessage('valid');
        } else {
            setPhoneNumberMessage('invalid')
        }
    };


    const onEdit = async (e) => {
        const id = e.target.id;
        const value = e.target.value;

        if (id === 'emailAddress') {
            const result = isValidEmail(value);

            if (result === null){
                setError('invalid');
            }else {
                setError('valid');
            }
        }

        if (id === 'phoneNumber') {
            isPhoneNumberValid(value);
        }
        setCreateItem({
            ...createItem,
            [id]: value,
        });
    }

    const reloadCaptcha = () => {
        setCaptcha(randomToken(6))
    }

    const resetItem = () => {
        setCreateItem({
            ...createItem,
            firstName: "",
            middleName: "",
            surname: "",
            emailAddress: "",
            phoneNumber: "",
            highestQualification: "",
            employmentStatus: "",
            areaOfTraining: "",
            organisation: "",
            jobLevel: "",
            appCaptcha: "",
        });
    };


    const applyNow = async () => {
        for (let key in createItem) {
            if (createItem.hasOwnProperty(key) && key !== "middleName") {
                if (createItem[key] === "") {
                    await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
                    return false;
                }
            }
        }

        if (error === "invalid") {
            showAlert('Input Error', 'Invalid Email Address', 'error' );
            return false;
        }

        if (phoneNumberMessage === "invalid") {
            showAlert('Input Error', 'Invalid Phone Number', 'error' );
            return false;
        }

        if (createItem.appCaptcha !== captcha) {
            showAlert('Captcha Error', 'Invalid Captcha Code', 'error' );
            return false;
        }

        const formData = {
            FirstName: createItem.firstName,
            MiddleName: createItem.middleName,
            Surname: createItem.surname,
            EmailAddress: createItem.emailAddress,
            PhoneNumber: createItem.phoneNumber,
            HighestQualification: createItem.highestQualification,
            AreaOfTraining: createItem.areaOfTraining,
            EmploymentStatus: createItem.employmentStatus,
            Organisation: createItem.organisation,
            JobLevel: createItem.jobLevel,
            CourseID: courseData[0].EntryID,
        }

        await axios.post(`${serverLink}short/course/application`, formData)
            .then(async res => {
                if (res.data.message === 'success') {
                    showAlert('APPLICATION SUCCESSFUL', 'We are pleased to inform you that your application has been successfully received. Our team will review and process your submission, and the outcome of your application will be communicated to you through the email address you have registered with us.', 'success');
                    const sendEmailData = {
                        from: 'itri@bazeuniversity.edu.ng',
                        to: createItem.emailAddress,
                        name: `${createItem.firstName} ${createItem.middleName} ${createItem.surname}`,
                        app_id: res.data.app_id,
                        course: courseData[0].CourseName
                    }
                    await axios.post(`${serverLink}short/course/send_email`, sendEmailData).then(r => {
                        setTimeout(() => {
                            resetItem();
                            reloadCaptcha();
                        }, 2000)
                    })
                } else if (res.data.message === 'exist') {
                    showAlert('Duplication Entry', 'You have already applied. Kindly exercise patient while we are working to get back to you!', 'error');
                } else {
                    showAlert('Error Submitting', 'Something went wrong submitting your application', 'error');
                }
            })
            .catch(err => {
                showAlert('Network Error: 500', 'Please check your network connection and try again!', 'error' );
            })
    }

    useEffect(() => {
        const slug_record = location.pathname.split("/")[location.pathname.split("/").length - 1];
        setCourseData(typeof props.HomeReport.course !== 'undefined' ? props.HomeReport.course.filter(i => i.Slug === slug_record) : []);
        reloadCaptcha();
    }, [location.pathname]);

    return <main>
        <Breadcrumb page_name={courseData.length > 0 ? courseData[0].CourseName : ''}/>

        <section className="course__area pt-10 pb-90">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-8 col-xl-8 col-lg-8">
                        <div className="course__wrapper">
                            <div className="course__tab-2 mb-45">
                                <ul className="nav nav-tabs" id="courseTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button
                                            type="button"
                                            className="nav-link active" id="description-tab" data-bs-toggle="tab"
                                                data-bs-target="#description" role="tab"
                                                aria-controls="description" aria-selected="true"><i
                                            className="fa-regular fa-medal"></i> <span>Overview</span></button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link " id="curriculum-tab" data-bs-toggle="tab"
                                                data-bs-target="#curriculum" type="button" role="tab"
                                                aria-controls="curriculum" aria-selected="false"><i
                                            className="fa-regular fa-book-blank"></i> <span>Curriculum</span></button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="member-tab" data-bs-toggle="tab"
                                                data-bs-target="#member" type="button" role="tab" aria-controls="member"
                                                aria-selected="false"><i className="fal fa-user"></i>
                                            <span>Facilitators</span></button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="review-tab" data-bs-toggle="tab"
                                                data-bs-target="#review" type="button" role="tab" aria-controls="review"
                                                aria-selected="false"><i className="fal fa-book"></i>
                                            <span>Apply</span></button>
                                    </li>
                                </ul>
                            </div>
                            <div className="course__tab-content mb-95">
                                <div className="tab-content" id="courseTabContent">
                                    <div className="tab-pane fade show active" id="description" role="tabpanel"
                                         aria-labelledby="description-tab">
                                        <div className="course__description">
                                            <div
                                                dangerouslySetInnerHTML={{__html: courseData.length > 0 ? courseData[0].Overview : ''}}/>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="curriculum" role="tabpanel" aria-labelledby="curriculum-tab">
                                        <div className="course__curriculum">

                                            {
                                                typeof props.HomeReport.module !== "undefined" &&
                                                props.HomeReport.module.filter(i => i.CourseID === parseInt(courseData.length > 0 ? courseData[0].EntryID : 0)).length > 0 &&
                                                props.HomeReport.module.filter(i => i.CourseID === parseInt(courseData.length > 0 ? courseData[0].EntryID : 0)).map((module, index) => {
                                                    return <div key={index} className="accordion"
                                                                id="course__accordion">
                                                        <div className="accordion-item mb-50">
                                                            <h2 className="accordion-header" id={module.ModuleCode}>
                                                                <button className="accordion-button" type="button"
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target={`#${module.ModuleCode}-content`}
                                                                        aria-expanded="true"
                                                                        aria-controls="week-01-content">
                                                                    {module.ModuleName}
                                                                </button>
                                                            </h2>
                                                            <div id={`${module.ModuleCode}-content`}
                                                                 className="accordion-collapse collapse show"
                                                                 aria-labelledby={module.ModuleCode}
                                                                 data-bs-parent="#course__accordion">
                                                                <div className="accordion-body">
                                                                    <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                                        <div className="course__curriculum-info">
                                                                            {module.Description}
                                                                        </div>
                                                                    </div>

                                                                    <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                                        <div className="course__curriculum-info">
                                                                            <h3><span>Module Code: </span></h3>
                                                                        </div>
                                                                        <div className="course__curriculum-meta">
                                                                            <span className="time"> <i className="icon_clock_alt"></i> {module.ModuleCode}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                                        <div className="course__curriculum-info">
                                                                            <h3><span>Credit Load: </span></h3>
                                                                        </div>
                                                                        <div className="course__curriculum-meta">
                                                                            <span className="time"> <i className="icon_clock_alt"></i> {module.CreditUnit}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="course__curriculum-content d-sm-flex justify-content-between align-items-center">
                                                                        <h4>Facilitators</h4>
                                                                    </div>
                                                                    {
                                                                        typeof props.HomeReport.tutor_enrol !== 'undefined' && props.HomeReport.tutor_enrol.filter(i => i.ModuleID === module.EntryID).length > 0 &&
                                                                        props.HomeReport.tutor_enrol.filter(i => i.ModuleID === module.EntryID).map((tutor, index) => {
                                                                            return <div key={index} className="course__member mb-45">
                                                                                <div className="course__member-item">
                                                                                    <div className="row align-items-center">
                                                                                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                                            <div className="course__member-thumb d-flex align-items-center">
                                                                                                <img src={tutor.ImageUrl} alt={tutor.StaffName}/>
                                                                                                <div className="course__member-name ml-20">
                                                                                                    <h5><Link to={`/tutor/${tutor.StaffSlug}`}>{tutor.StaffName}</Link></h5>
                                                                                                    <span>{tutor.Qualification}</span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="member" role="tabpanel" aria-labelledby="member-tab">
                                        <div className="course__member mb-45">
                                            {
                                                typeof props.HomeReport.tutor_list !== 'undefined' && props.HomeReport.tutor_list.length > 0 &&
                                                props.HomeReport.tutor_list.map((tutor, index) => {
                                                    if (props.HomeReport.tutor_enrol.filter(v => v.StaffID === tutor.EntryID && v.CourseID === courseData[0].EntryID).length > 0) {
                                                        return <div key={index} className="course__member-item">
                                                            <div className="row align-items-center">
                                                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                    <div className="course__member-thumb d-flex align-items-center">
                                                                        <img src={tutor.ImageUrl} alt={tutor.StaffName}/>
                                                                        <div className="course__member-name ml-20">
                                                                            <h5><Link to={`/tutor/${tutor.Slug}`}>{tutor.StaffName}</Link></h5>
                                                                            <span>{tutor.Qualification}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                })
                                            }

                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="review" role="tabpanel" aria-labelledby="review-tab">
                                        <div className="course__review">
                                            <div className="course__form">
                                                <h3 className="course__form-title">Enrol Now</h3>
                                                <div className="course__form-inner">
                                                    <div className="row">
                                                        <div className="col-xxl-4">
                                                            <div className="course__form-input">
                                                                <input
                                                                    type="text"
                                                                    placeholder="First Name*"
                                                                    id={"firstName"}
                                                                    className="form-control"
                                                                    required
                                                                    value={createItem.firstName}
                                                                    onChange={onEdit}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-4">
                                                            <div className="course__form-input">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Middle Name"
                                                                    id={"middleName"}
                                                                    className="form-control"
                                                                    required
                                                                    value={createItem.middleName}
                                                                    onChange={onEdit}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-4">
                                                            <div className="course__form-input">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Surname*"
                                                                    id={"surname"}
                                                                    className="form-control"
                                                                    value={createItem.surname}
                                                                    onChange={onEdit}

                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-6">
                                                            <div className="course__form-input">
                                                                <input
                                                                    type="email"
                                                                    placeholder="Email*"
                                                                    required
                                                                    id={"emailAddress"}
                                                                    className="form-control"
                                                                    value={createItem.emailAddress}
                                                                    onChange={onEdit}
                                                                 />

                                                                {error === "invalid" && (
                                                                    <span style={{backgroundColor: 'red', color: 'white', fontSize: '12px', padding: '5px', marginTop: '5px'}}>
                                                                  {error && (
                                                                      <>
                                                                          {error === "invalid" && "Email is not valid" }
                                                                      </>
                                                                  )}
                                                                </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-6">
                                                            <div className="course__form-input">
                                                                <input
                                                                    type="tex"
                                                                    placeholder="Phone Number: +23408136791100"
                                                                    id={"phoneNumber"}
                                                                    required
                                                                    className="form-control"
                                                                    value={createItem.phoneNumber}
                                                                    onChange={onEdit}
                                                                  />
                                                                {phoneNumberMessage === "invalid" && (
                                                                    <span style={{backgroundColor: 'red', color: 'white', fontSize: '12px', padding: '5px', marginTop: '5px'}}>
                                                                  {phoneNumberMessage && (
                                                                      <>
                                                                          {phoneNumberMessage === "invalid" && "Invalid phone number, it must be in this format: +23408136791100" }
                                                                      </>
                                                                  )}
                                                                </span>
                                                                )}

                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-6">
                                                            <div className="course__form-input">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Organisation / Place of Work"
                                                                    id={"organisation"}
                                                                    required
                                                                    className="form-control"
                                                                    value={createItem.organisation}
                                                                    onChange={onEdit}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-6">
                                                            <div className="country-select">
                                                                <select
                                                                    id="highestQualification"
                                                                    className="form-control"
                                                                    required
                                                                    value={createItem.highestQualification}
                                                                    onChange={onEdit}>
                                                                    <option value="">Highest Qualification</option>
                                                                    <option value="SSCE">SSCE</option>
                                                                    <option value="Diploma">Diploma</option>
                                                                    <option value="NCE">NCE</option>
                                                                    <option value="BSc">BSc</option>
                                                                    <option value="Postgraduate Diploma">Postgraduate Diploma</option>
                                                                    <option value="MSc">MSc</option>
                                                                    <option value="MBA">MBA</option>
                                                                    <option value="PhD">PhD</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-4">
                                                            <div className="country-select">
                                                                <select id="areaOfTraining"
                                                                        className="form-control"
                                                                        required
                                                                        value={createItem.areaOfTraining}
                                                                        onChange={onEdit}>
                                                                    <option value="">Area Of Training</option>
                                                                    <option value="Artificial Intelligence">Artificial Intelligence</option>
                                                                    <option value="Accounting">Accounting</option>
                                                                    <option value="Architecture">Architecture</option>
                                                                    <option value="Banking and Finance">Banking and Finance</option>
                                                                    <option value="Business Analytics">Business Analytics</option>
                                                                    <option value="Business Administration">Business Administration</option>
                                                                    <option value="Blockchain">Blockchain</option>
                                                                    <option value="Computer Hardware">Computer Hardware</option>
                                                                    <option value="Cloud Computing">Cloud Computing</option>
                                                                    <option value="Computer Networks">Data Science</option>
                                                                    <option value="Data Analytics">Data Analytics</option>
                                                                    <option value="Economics">Economics</option>
                                                                    <option value="Engineering">Engineering</option>
                                                                    <option value="Human Resource">Human Resource</option>
                                                                    <option value="Law">Law</option>
                                                                    <option value="Mathematics">Mathematics</option>
                                                                    <option value="Machine Learning">Machine Learning</option>
                                                                    <option value="Military Personnel">Military Personnel</option>
                                                                    <option value="Medicine">Medicine</option>
                                                                    <option value="Network Security">Network Security</option>
                                                                    <option value="Paramilitary Personnel">Paramilitary Personnel</option>
                                                                    <option value="Physics">Physics</option>
                                                                    <option value="Software Development">Software Development</option>
                                                                    <option value="Others">Others</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-4">
                                                            <div className="country-select">
                                                                <select id="employmentStatus"
                                                                        className="form-control"
                                                                        required
                                                                        value={createItem.employmentStatus}
                                                                        onChange={onEdit}>
                                                                    <option value="">Employment Status</option>
                                                                    <option value="Employed">Employed</option>
                                                                    <option value="Unemployed">Unemployed</option>
                                                                    <option value="Self-Employed">Self-Employed</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-4">
                                                            <div className="country-select">
                                                                <select id="jobLevel"
                                                                        className="form-control"
                                                                        required
                                                                        value={createItem.jobLevel}
                                                                        onChange={onEdit}>
                                                                    <option value="">Job Level</option>
                                                                    <option value="Entry-leve">Entry-leve</option>
                                                                    <option value="Junior">Junior</option>
                                                                    <option value="Junior Executive">Junior Executive</option>
                                                                    <option value="Senior">Senior</option>
                                                                    <option value="Senior Executive">Senior Executive</option>
                                                                    <option value="Mid-Management">Mid-Management</option>
                                                                    <option value="Management">Management</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-3">
                                                            <h3>{captcha}</h3>
                                                            <button onClick={reloadCaptcha}>Reload</button>
                                                        </div>
                                                        <div className="col-xxl-9">
                                                            <div className="course__form-input">
                                                                <input
                                                                    type="text"
                                                                    placeholder="Enter Captcha"
                                                                    id={"appCaptcha"}
                                                                    value={createItem.appCaptcha}
                                                                    onChange={onEdit}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xxl-12">
                                                            <div className="course__form-btn mt-10 mb-55">
                                                                <button type="submit" className="tp-btn"  style={{ backgroundColor: "#63656a" }} onClick={applyNow}>Apply</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-4 col-xl-4 col-lg-4">
                        <div className="course__sidebar pl-70 p-relative">
                            <div className="course__sidebar-widget-2 white-bg mb-20">
                                <div className="course__video">

                                    <div
                                        className="course__video-meta mb-25 d-flex align-items-center justify-content-between">
                                        <div className="course__video-price">
                                            <h5>{courseData.length > 0 ? currencyConverter(courseData[0].Price) : ''}</h5>
                                        </div>
                                    </div>
                                    <div className="course__video-content mb-35">
                                        <ul>
                                            <li className="d-flex align-items-center">
                                                <div className="course__video-icon">
                                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px"
                                                         y="0px"
                                                         viewBox="0 0 16 16" style={{enableBackground: 'new 0 0 16 1'}}
                                                    >
                                                        <circle className="st0" cx="8" cy="8" r="6.7"/>
                                                        <polyline className="st0" points="8,4 8,8 10.7,9.3 "/>
                                                    </svg>
                                                </div>
                                                <div className="course__video-info">
                                                    <h5><span>Duration :</span>{courseData.length > 0 ? `${courseData[0].Duration} ${courseData[0].DurationType}` : ''}</h5>
                                                </div>
                                            </li>

                                            <li className="d-flex align-items-center">
                                                <div className="course__video-icon">
                                                    <svg>
                                                        <circle className="st0" cx="8" cy="8" r="6.7"/>
                                                        <line className="st0" x1="1.3" y1="8" x2="14.7" y2="8"/>
                                                        <path className="st0"
                                                              d="M8,1.3c1.7,1.8,2.6,4.2,2.7,6.7c-0.1,2.5-1,4.8-2.7,6.7C6.3,12.8,5.4,10.5,5.3,8C5.4,5.5,6.3,3.2,8,1.3z"/>
                                                    </svg>
                                                </div>
                                                <div className="course__video-info">
                                                    <h5><span>Mode of Delivery :</span>{courseData.length > 0 ? courseData[0].ModeOfTeaching : ''}</h5>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="course__sidebar-widget-2 white-bg mb-20">
                                <div className="course__sidebar-course">
                                    <h3 className="course__sidebar-title">Related Programs</h3>
                                    <ul>
                                        {
                                           props.HomeReport.course.length > 0 &&
                                            props.HomeReport.course.map((course, index) => {
                                                if (index < 4) {
                                                    if (course.DepartmentID === courseData[0].DepartmentID) {
                                                        return <li key={index}>
                                                            <div className="course__sm d-flex align-items-center mb-30">
                                                                <div className="course__sm-content">
                                                                    <h5>
                                                                        <Link to={`/program/${course.Slug}`}>{course.CourseName}</Link></h5>
                                                                    <div className="course__sm-price">
                                                                        <span>{currencyConverter(course.Price)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    }
                                                }
                                            })
                                        }


                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <div className="course__popup">

            <div className="modal fade" id="course_enroll_modal" tabIndex="-1" aria-labelledby="course_enroll_modal"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="course__popup-wrapper p-relative">
                            <div className="course__popup-close">
                                <button type="button" className="course__popup-close-btn" data-bs-toggle="modal"
                                        data-bs-target="#course_enroll_modal"><i className="fa-light fa-xmark"></i>
                                </button>
                            </div>
                            <div className="course__popup-top d-flex align-items-start mb-40">
                                <div className="course__popup-thumb mr-20">
                                    <img src="assets/img/course/course-sm-1.jpg" alt=""/>
                                </div>
                                <div className="course__popup-content">
                                    <h3 className="course__popup-title">
                                        <a href="#">Mechanical Engineering and Electrical Engineering Explained</a>
                                    </h3>
                                    <div className="course__sm-rating">
                                        <ul>
                                            <li><a href="#"> <i className="fa-solid fa-star"></i> </a></li>
                                            <li><a href="#"> <i className="fa-solid fa-star"></i> </a></li>
                                            <li><a href="#"> <i className="fa-solid fa-star"></i> </a></li>
                                            <li><a href="#"> <i className="fa-solid fa-star"></i> </a></li>
                                            <li><a href="#"> <i className="fa-solid fa-star"></i> </a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="course__popup-info">
                                <form action="#">
                                    <div className="row gx-3">
                                        <div className="col-xl-12">
                                            <div className="course__popup-input">
                                                <input type="email" placeholder="Email"/>
                                                <span className="course__popup-input-icon"><i
                                                    className="fa-light fa-envelope"></i></span>
                                            </div>
                                        </div>
                                        <div className="col-xxl-12">
                                            <div className="course__popup-input">
                                                <input type="text" placeholder="Card Number"/>
                                                <span className="course__popup-input-icon"><i
                                                    className="fa-light fa-credit-card"></i></span>
                                            </div>
                                        </div>
                                        <div className="col-xxl-6">
                                            <div className="course__popup-input">
                                                <input type="text" placeholder="MM/YY"/>
                                                <span className="course__popup-input-icon"><i
                                                    className="fa-light fa-calendar-days"></i></span>
                                            </div>
                                        </div>
                                        <div className="col-xxl-6">
                                            <div className="course__popup-input">
                                                <input type="text" placeholder="CVC"/>
                                                <span className="course__popup-input-icon"><i
                                                    className="fa-light fa-lock"></i></span>
                                            </div>
                                        </div>
                                        <div className="col-xxl-12">
                                            <div className="course__popup-btn">
                                                <button type="submit" className="tp-btn w-100">Pay $10.00</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </main>
}
const mapStateToProps = (state) => {
    return {
        HomeReport: state.HomeReport
    };
};

export default connect(mapStateToProps, null)(CourseDetail);
