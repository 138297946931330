import React from "react";
import {Link} from "react-router-dom";

const Footer = (props) => {


    return(
        <>
            <footer>
                <div className="footer__area">
                    <div className="footer__top grey-bg-4 pt-95 pb-45">
                        <div className="container">
                            <div className="row">
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-7">
                                    <div className="footer__widget footer__widget-3 footer-col-3-1 mb-50">
                                        <div className="footer__logo">
                                            <div className="logo">
                                                <a href="index.html">
                                                    <img src="assets/img/logo/logo-3.png" alt="" />
                                                </a>
                                            </div>
                                        </div>
                                        <div className="footer__widget-content">
                                            <div className="footer__widget-info">
                                                <div className="footer__subscribe footer__subscribe-3">
                                                    <p>Baze University offers a range of professional certificate programs. You can enrol for any of these programs and study with us online or physical.</p>
                                                    <form action="/contact">
                                                        <div className="footer__subscribe-input">
                                                            <input type="text" placeholder="Email" />
                                                                <button type="submit"
                                                                        className="tp-btn-subscribe">Subscribe
                                                                </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-3 col-sm-5">
                                    <div className="footer__widget footer__widget-3 footer-col-3-2 mb-50">
                                        <h3 className="footer__widget-title footer__widget-title-3">Explore</h3>
                                        <div className="footer__widget-content">
                                            <ul>


                                                <li>
                                                    <Link to={"/home"}>Home</Link>
                                                </li>

                                                <li>
                                                    <Link to={"/contact"}>Contact</Link>
                                                </li>

                                                <li>
                                                    <Link to={"/our-programs"}>Our Programs</Link>
                                                </li>

                                                <li>
                                                    <Link to={"/admission-fees"}>Program Guideline</Link>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-3 col-xl-2 col-lg-2 col-md-3 col-sm-5">
                                    <div className="footer__widget footer__widget-3 footer-col-3-3 mb-50">
                                        <h3 className="footer__widget-title footer__widget-title-3">Partners</h3>
                                        <div className="footer__widget-content">
                                            <ul>
                                                <li>
                                                    <a target="_blank" href="https://www.ibm.com/training/Y495254M82933R94?t=tab3-link">IBM Africa</a>
                                                </li>
                                                <li>
                                                    <a target="_blank" href="https://www.microsoft.com/en-us/research/group/microsoft-africa-research-institute-mari/">Microsoft Africa Research Institute (MARI)</a>
                                                </li>
                                                <li>
                                                    <a target="_blank" href="https://www.netacad.com/">Cisco</a>
                                                </li>
                                                <li>
                                                    <a target="_blank" href="https://www.oracle.com/ng/education/">Oracle Academy</a>
                                                </li>
                                                <li>
                                                    <a target="_blank" href="https://www.domineum.io">Domineum</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-7">
                                    <div className="footer__widget footer__widget-3 footer-col-3-4 mb-50">
                                        <h3 className="footer__widget-title footer__widget-title-3">Information</h3>

                                        <div className="footer__contact">
                                            <ul>
                                                <li>
                                                    <p>Office Address:</p>
                                                    <h4><a
                                                        href="https://www.google.com/maps/place/%E0%A6%A2%E0%A6%BE%E0%A6%95%E0%A6%BE/@23.7807777,90.3492857,12z/data=!3m1!4b1!4m5!3m4!1s0x3755b8b087026b81:0x8fa563bbdd5904c2!8m2!3d23.810332!4d90.4125181"
                                                        target="_blank">Room A34 Baze University, Abuja</a></h4>
                                                </li>
                                                <li>
                                                    <p>Phone Number:</p>
                                                    <h4><a href="tel:088-234-567-899">(+234) 123 456 7890</a></h4>
                                                </li>
                                                <li>
                                                    <p>Email Address</p>
                                                    <h4><a href="mailto:itri@bazeuniversity.edu.ng">itri@bazeuniversity.edu.ng</a></h4>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer__bottom grey-bg-4">
                        <div className="container">
                            <div className="footer__bottom-inner">
                                <div className="row">
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                        <div className="footer__bottom-link">
                                            <ul>

                                                <li>
                                                    <Link to={"/home"}>Disclaimer &amp; Copyright</Link>
                                                </li>
                                                <li>
                                                    <Link to={"/home"}>Accessibility</Link>
                                                </li>
                                                <li>
                                                    <Link to={"/home"}>Privacy</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                                        <div className="footer__social footer__social-3 text-md-end">
                                            <ul>
                                                <li>
                                                    <a target="_blank" href='https://www.facebook.com/BazeUniversity'>
                                                        <i className="fa-brands fa-facebook-f"></i>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a   target="_blank" href='https://twitter.com/Baze_University'><i className="fa-brands fa-twitter"></i></a>
                                                </li>

                                                <li>
                                                    <a  target="_blank" href='https://www.linkedin.com/school/bazeuniversity/mycompany/'><i className="fa-brands fa-linkedin-in"></i></a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
