import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import {setHomePageData} from "../../../action/actions";
import {Link} from "react-router-dom";


const Overlay = (props) => {

    return (
        <>
            <div className="offcanvas__area">
                <div className="modal fade" id="offcanvasmodal" tabIndex="-1" aria-labelledby="offcanvasmodal"
                     aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="offcanvas__wrapper">
                                <div className="offcanvas__content">
                                    <div
                                        className="offcanvas__top mb-40 d-flex justify-content-between align-items-center">
                                        <div className="offcanvas__logo logo">
                                            <a href="index.html">
                                                <img src="assets/img/logo/logo-3.png" alt="logo" />
                                            </a>
                                        </div>
                                        <div className="offcanvas__close">
                                            <button className="offcanvas__close-btn" data-bs-toggle="modal"
                                                    data-bs-target="#offcanvasmodal">
                                                <i className="fal fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="offcanvas__search mb-25">
                                        <form action="#">
                                            <input type="text" placeholder="What are you searching for?" />
                                                <button type="submit"><i className="far fa-search"></i></button>
                                        </form>
                                    </div>

                                    <div className="mobile-menu fix mean-container">
                                        <div className="mean-bar"><a href="#nav" className="meanmenu-reveal"
                                                                     style={{ right: "0px", left: "auto", display: "inline" }}><span><span><span></span></span></span></a>

                                            <nav className="mean-nav">
                                                <ul>
                                                    <li data-bs-toggle="modal" data-bs-target="#offcanvasmodal">
                                                        <Link to={"/home"}>Home</Link>
                                                    </li>
                                                    <li data-bs-toggle="modal" data-bs-target="#offcanvasmodal">
                                                        <Link to={"/our-programs"}>Programs</Link>
                                                    </li>

                                                    <li data-bs-toggle="modal" data-bs-target="#offcanvasmodal">
                                                        <Link to={"/admission-fees"}>Admission & Fees</Link>
                                                    </li>
                                                    <li data-bs-toggle="modal" data-bs-target="#offcanvasmodal">
                                                        <Link to={"/contact"}>Contact</Link>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>

                                    {/*<div className="offcanvas__map d-none d-lg-block mb-15">*/}
                                    {/*    <iframe*/}
                                    {/*        src="https://www.google.com/maps/dir/9.0063752,7.4041769/baze+university+google+map/@9.0069148,7.4027285,17z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x104e7372e355f317:0x7f90ec48624fd5b4!2m2!1d7.4059828!2d9.0073052"></iframe>*/}
                                    {/*</div>*/}
                                    <div className="offcanvas__contact mt-30 mb-20">
                                        <h4>Contact Info</h4>
                                        <ul>
                                            <li className="d-flex align-items-center">
                                                <div className="offcanvas__contact-icon mr-15">
                                                    <i className="fal fa-map-marker-alt"></i>
                                                </div>
                                                <div className="offcanvas__contact-text">
                                                    <a target="_blank"
                                                       href="https://www.google.com/maps/dir/9.0063752,7.4041769/baze+university+google+map/@9.0069148,7.4027285,17z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x104e7372e355f317:0x7f90ec48624fd5b4!2m2!1d7.4059828!2d9.0073052">
                                                        Plot 686, Jabi Airport Road Bypass, Cadastral Zone, Abuja</a>
                                                </div>
                                            </li>
                                            <li className="d-flex align-items-center">
                                                <div className="offcanvas__contact-icon mr-15">
                                                    <i className="far fa-phone"></i>
                                                </div>
                                                <div className="offcanvas__contact-text">
                                                    <a href="mailto:support@gmail.com">(+234) 123 456 7890</a>
                                                </div>
                                            </li>
                                            <li className="d-flex align-items-center">
                                                <div className="offcanvas__contact-icon mr-15">
                                                    <i className="fal fa-envelope"></i>
                                                </div>
                                                <div className="offcanvas__contact-text">
                                                    <a href="tel:+012-345-6789">itri@bazeuniversity.edu.ng</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    {/*<div className="offcanvas__social">*/}
                                    {/*    <ul>*/}
                                    {/*        <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>*/}
                                    {/*        <li><a href="#"><i className="fab fa-twitter"></i></a></li>*/}
                                    {/*        <li><a href="#"><i className="fab fa-youtube"></i></a></li>*/}
                                    {/*        <li><a href="#"><i className="fab fa-linkedin"></i></a></li>*/}
                                    {/*    </ul>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="body-overlay"></div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        homePageData: state.HomePageDataReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnHomePageData: (p) => {
            dispatch(setHomePageData(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overlay);
