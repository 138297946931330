export const HomePageDataReducer = (state=[], action)=>{
    switch(action.type){
        case "SET_HOMEPAGE_DATA":
            return action.payload;
        default:
            return state;
    }
}

export const HomeReportReducer = (state=[], action)=>{
    switch(action.type){
        case "SET_HOME_REPORT_DATA":
            return action.payload;
        default:
            return state;
    }
}
