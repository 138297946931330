import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import {setHomePageData} from "../../../action/actions";


const LoaderProgressBar = (props) => {

    return (
        <>
            <div id="loading">
                <div id="loading-center">
                    <div id="loading-center-absolute">
                        <svg id="loader">
                            <path id="corners" d="m 0 12.5 l 0 -12.5 l 50 0 l 0 50 l -50 0 l 0 -37.5"></path>
                        </svg>
                        <img src="assets/img/favicon.png" alt="" />
                    </div>
                </div>
            </div>

            <div className="progress-wrap active-progress">
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"></path>
                </svg>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        homePageData: state.HomePageDataReducer,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnHomePageData: (p) => {
            dispatch(setHomePageData(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoaderProgressBar);
