import React from "react";
import {Route, Routes} from 'react-router-dom'
import Home from "../../home/home";
import Header from "../../common/header/header";
import HeaderSticky from "../../common/header-sticky/header-sticky";
import Footer from "../../common/footer/footer";
import Overlay from "../../common/overlay/overlay";
import LoaderProgressBar from "../../common/loader-progress-bar/loader-progress-bar";
import Slider from "../../common/slider/slider";
import OurProgram from "../../course/our-program/our-program";
import CourseDetail from "../../course/detail/course-detail";
import Contact from "../../contact/contact";
import StaffProfile from "../../staff-profile/staff-profile";
import AdmissionAndFees from "../../admission-fees/admission-fees";
import AdmissionLetter from "../../admission-letter/admission-letter";

const PublicRoute = (props) => {
    return (
        <div>
            {/*<LoaderProgressBar />*/}
            <Header />
            <HeaderSticky />
            <Overlay />

            <main>
                <Routes>
                    <Route path={"/home"} element={<Home />} />
                    <Route path={"/our-programs"} element={<OurProgram />} />
                    <Route path={"/program/:slug"} element={<CourseDetail />} />
                    <Route path={"/contact"} element={<Contact />} />
                    <Route path={"/tutor/:slug"} element={<StaffProfile />} />
                    <Route path={"/admission-fees"} element={<AdmissionAndFees />} />
                    <Route path={"/admission-letter/:ApplicationID"} element={<AdmissionLetter />} />
                    <Route path="*" element={<Home />} />
                </Routes>
            </main>
            <Footer/>
        </div>
    )
}
export default PublicRoute;
