import React, { useEffect, useState } from "react";
import {Link, useParams} from "react-router-dom";
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import { showAlert } from "../common/sweetalert/sweetalert";
import axios from "axios";
import { connect } from "react-redux";
import {currencyConverter, serverLink} from "../../components/url";
import {setHomePageData} from "../../action/actions";
import guideline from "../../images/guideline.jpg";
import ContentLoader from "../common/loader/content-loader";

const AdmissionAndFees = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [faq] = useState(typeof props.homePageData.guideline !== 'undefined' ? props.homePageData.guideline : []);
    const [fee] = useState(typeof props.homePageData.finance !== 'undefined' ? props.homePageData.finance : []);
    const [details] = useState(typeof props.homePageData.guideline_details !== 'undefined' ? props.homePageData.guideline_details : []);


    useEffect(() => {
        if (faq.length > 0 || details.length > 0){
            setIsLoading(false)
        }
    }, []);


    return isLoading ? (
        <ContentLoader />
    ) : (
        <>
            <Breadcrumb page_name={"Program Guideline"} />
            <>

                <section class="event__area pt-115 p-relative">
                    <div class="page__title-shape">
                        <img class="page-title-shape-5 d-none d-sm-block" src="assets/img/breadcrumb/page-title-shape-1.png" alt="" />
                        <img class="page-title-shape-6" src="assets/img/breadcrumb/page-title-shape-2.png" alt="" />
                        <img class="page-title-shape-7" src="assets/img/breadcrumb/page-title-shape-4.png" alt="" />
                        <img class="page-title-shape-8" src="assets/img/breadcrumb/page-title-shape-5.png" alt="" />
                    </div>
                    <div class="container">
                        <div class="row">
                            <div class="col-xxl-8">
                                <div class="event__wrapper">
                                    <div class="page__title-content mb-25">

                                        <h5 class="breadcrumb__title-2" style={{ fontSize: '40px' }}>Program Information and Guideline</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <section class="event__area pb-110">
                    <div class="container">
                        <div class="row">
                            <div class="col-xxl-8 col-xl-8 col-lg-8">
                                <div class="event_wrapper">
                                    <div class="event__thumb mb-35 w-img">
                                        <img src={guideline} alt=""/>
                                    </div>
                                    <div class="event__details mb-35">
                                        <h3>General Program Information</h3>


                                        <div className="row">
                                            <div className="faq__item-wrapper">
                                                <div className="faq__accordion">

                                                    {faq.length && (
                                                        <>
                                                            {faq.map((item, index) => {
                                                                return (
                                                                    <div key={index} className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqOne" >
                                                                            <button className="accordion-button collapsed" type="button"
                                                                                    data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                                                    aria-expanded="true" aria-controls="collapseTwo">
                                                                                {item.Title}
                                                                            </button>
                                                                        </h2>
                                                                        <div id="collapseOne" className="accordion-collapse collapse show"
                                                                             aria-labelledby="faqOne" data-bs-parent="#faqaccordion">
                                                                            <div className="accordion-body">
                                                                                <p>{item.Content}</p>
                                                                                {details.filter((i) => i.GuidelineID === item.EntryID).length > 0 ? (
                                                                                    <>
                                                                                        {details.filter((i) =>
                                                                                            i.GuidelineID === item.EntryID).map((item, index) => {
                                                                                            return(
                                                                                                <div key={index}>

                                                                                                    <ul style={{ listStyleType: "none" }}>
                                                                                                        <li>
                                                                                                            <i className="fal fa-check"></i>{" "}
                                                                                                            <strong>{item.Title}</strong> -  {" "} {item.Content}
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                    </>
                                                                                ): (null)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="event__details mb-35">
                                        <h3>Fees Structure and Information</h3>


                                        <div className="row">
                                            <div className="faq__item-wrapper">
                                                <div className="faq__accordion">

                                                    {fee.length && (
                                                        <>
                                                            {fee.map((item, index) => {
                                                                return (
                                                                    <div key={index} className="accordion-item">
                                                                        <h2 className="accordion-header" id="faqOne">
                                                                            <button
                                                                                className="accordion-button collapsed"
                                                                                type="button"
                                                                                data-bs-toggle="collapse"
                                                                                data-bs-target="#collapseOne"
                                                                                aria-expanded="true"
                                                                                aria-controls="collapseTwo">
                                                                                {item.Title}
                                                                            </button>
                                                                        </h2>
                                                                        <div id="collapseOne"
                                                                             className="accordion-collapse collapse show"
                                                                             aria-labelledby="faqOne"
                                                                             data-bs-parent="#faqaccordion">
                                                                            <div className="accordion-body">
                                                                                <p>{item.Content}</p>
                                                                                {details.filter((i) => i.GuidelineID === item.EntryID).length > 0 ? (
                                                                                    <>
                                                                                        {details.filter((i) =>
                                                                                            i.GuidelineID === item.EntryID).map((item, index) => {
                                                                                            return (
                                                                                                <div key={index}>

                                                                                                    <ul style={{listStyleType: "none"}}>
                                                                                                        <li>
                                                                                                            <i className="fal fa-check"></i>{" "}
                                                                                                            <strong>{item.Title}</strong> - {" "} {item.Content}
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                    </>
                                                                                ) : (null)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="event__allow mb-40">
                                        <h3>For more Information and Clarification</h3>
                                        <ul>
                                            <li><i class="fal fa-check"></i><a href="mailto:itri@bazeuniversity.edu.ng">itri@bazeuniversity.edu.ng</a></li>
                                            <li><i class="fal fa-check"></i><a href="tel:+234-567-899">(+234) 123 456 7890</a></li>
                                            <li><i class="fal fa-check"></i>
                                                <Link to={"/contact"}>
                                                    Send Request
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xxl-4 col-xl-4 col-lg-4">
                                <div class="event__sidebar pl-70">
                                    <div class="event__sidebar-widget white-bg mb-20">
                                        <div class="event__sidebar-shape">
                                            <img class="event-sidebar-img-2" src="assets/img/events/event-shape-2.png" alt=""/>
                                            <img class="event-sidebar-img-3" src="assets/img/events/event-shape-3.png" alt=""/>
                                        </div>
                                        <div class="event__info">
                                            <div className="event__info-content mb-35">
                                                <ul>
                                                    <li className="d-flex align-items-center">
                                                        <div className="event__info-icon">
                                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                                 xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
                                                                 y="0px" viewBox="0 0 16 16"
                                                                 style={{enablebackground:"new 0 0 16 16"}}
                                                                 xmlSpace="preserve">
                                                                <path className="st0"
                                                                      d="M2,6l6-4.7L14,6v7.3c0,0.7-0.6,1.3-1.3,1.3H3.3c-0.7,0-1.3-0.6-1.3-1.3V6z"/>
                                                                <polyline className="st0"
                                                                          points="6,14.7 6,8 10,8 10,14.7 "/>
                                                            </svg>
                                                        </div>
                                                        <div className="event__info-item">
                                                            <h5><span>Duration: </span>4 Weeks Per Cohort</h5>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex align-items-center">
                                                        <div className="event__info-icon">
                                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                                 xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
                                                                 y="0px" viewBox="0 0 16 16"
                                                                 style={{enablebackground:"new 0 0 16 16"}}
                                                                 xmlSpace="preserve">
                                                                <path className="st0"
                                                                      d="M2,6l6-4.7L14,6v7.3c0,0.7-0.6,1.3-1.3,1.3H3.3c-0.7,0-1.3-0.6-1.3-1.3V6z"/>
                                                                <polyline className="st0"
                                                                          points="6,14.7 6,8 10,8 10,14.7 "/>
                                                            </svg>
                                                        </div>
                                                        <div className="event__info-item">
                                                            <h5><span>Time:</span>  Friday and Saturday 5:00PM - 7:30PM</h5>
                                                        </div>
                                                    </li>
                                                    <li className="d-flex align-items-center">
                                                        <div className="event__info-icon">
                                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                                 xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
                                                                 y="0px" viewBox="0 0 16 16"
                                                                 style={{enablebackground:"new 0 0 16 16"}}
                                                                 xmlSpace="preserve">
                                                                <path className="st0"
                                                                      d="M2,6l6-4.7L14,6v7.3c0,0.7-0.6,1.3-1.3,1.3H3.3c-0.7,0-1.3-0.6-1.3-1.3V6z"/>
                                                                <polyline className="st0"
                                                                          points="6,14.7 6,8 10,8 10,14.7 "/>
                                                            </svg>
                                                        </div>
                                                        <div className="event__info-item">
                                                            <h5><span>Venue: </span>On-campus/Online (Hybrid)</h5>
                                                        </div>
                                                    </li>

                                                    <li className="d-flex align-items-center">
                                                        <div className="event__info-icon">
                                                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                                                 xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
                                                                 y="0px" viewBox="0 0 16 16"
                                                                 style={{enablebackground:"new 0 0 16 16"}}
                                                                 xmlSpace="preserve">
                                                                <path className="st0"
                                                                      d="M2,6l6-4.7L14,6v7.3c0,0.7-0.6,1.3-1.3,1.3H3.3c-0.7,0-1.3-0.6-1.3-1.3V6z"/>
                                                                <polyline className="st0"
                                                                          points="6,14.7 6,8 10,8 10,14.7 "/>
                                                            </svg>
                                                        </div>
                                                        <div className="event__info-item">
                                                            <h5><span>Programme Fee: </span>N120,000</h5>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="event__join-btn">
                                                <Link to={'/our-programs'} class="tp-btn text-center w-100" style={{ backgroundColor: "#63656a" }}>Enroll Now <i class="far fa-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        homePageData: state.HomeReport,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setOnHomePageData: (p) => {
            dispatch(setHomePageData(p));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdmissionAndFees);