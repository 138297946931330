import React from "react";
import { ThreeCircles } from "react-loader-spinner";
export default function ContentLoader() {
    return (
        <div className="main-content-wrap sidenav-open d-flex flex-column">
            <div
                style={{
                    display: "block",
                    marginTop: "240px",
                    marginLeft: "auto",
                    marginRight: "auto",
                }}
            >
                <ThreeCircles
                    height="150"
                    width="150"
                    color="#63656a"
                    ariaLabel="loading"
                />
            </div>
            <div className="pt-100"></div>
        </div>
    );
}