import { useState } from 'react';
import './admission-letter.css'
import {
    currencyConverter,
    convertNumbertoWords,
    formatDateAndTime,
    serverLink,
    generate_token,
    paymentAPIKey, projectLogo
} from '../url';
import BgImage from '../../images/logo-3.png'
import LogoB from '../../images/logo-3.png'
import VCSign from '../../images/baze_vc_sign.png';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';

import {usePaystackPayment} from "react-paystack";
import {showAlert} from "../common/sweetalert/sweetalert";

const AdmissionLetter = (props) => {
    const { ApplicationID } = useParams();
    const componentRef = useRef();
    const [showAdmissionLetter, setShowAdmissionLetter] = useState(false)
    const [data, setData] = useState([])
    const transactionRef = generate_token(10);

    const [formData, setFormData] = useState({
        EntryID: 1,
        FirstName: "",
        MiddleName: "",
        Surname: "",
        EmailAddress: "",
        PhoneNumber: "",
        Organisation: "",
        HighestQualification: "",
        AreaOfTraining: "",
        EmploymentStatus: "",
        CourseID: "",
        CourseName:"",
        JobLevel: "",
        Cohort: "",
        CohortCode: "",
        CohortName: "",
        Status: "",
        DepartmentID: "",
        DurationType: "",
        ModeOfTeaching: "",
        Price: 0,
        ApplicationID: "",
    })
    const config = {
        reference: transactionRef,
        email: "ahmad.idris@bazeuniversity.edu.ng",
        amount: formData.Price * 100,
        publicKey: paymentAPIKey,
        metadata: {
            EntryID: formData.EntryID,
            CourseID: formData.CourseID,
            CourseName: formData.CourseName,
            ApplicationID: formData.ApplicationID,
            FirstName: formData.FirstName,
            MiddleName: formData.MiddleName,
            Surname: formData.Surname,
            EmailAddress: formData.EmailAddress,
            PhoneNumber: formData.PhoneNumber,
            Organisation: formData.Organisation,
            HighestQualification: formData.HighestQualification,
            AreaOfTraining: formData.AreaOfTraining,
            EmploymentStatus: formData.EmploymentStatus,
            JobLevel: formData.JobLevel,
            Cohort: formData.CohortCode,
            CohortName: formData.CohortName,
            Status: formData.Status,
            DepartmentID: formData.DepartmentID,
            DurationType: formData.DurationType,
            ModeOfTeaching: formData.ModeOfTeaching,
            Reference: transactionRef,
            Price: formData.Price,
            payment_for: "Short Course",
            custom_fields: [
                {
                    display_name: "Applicant ID",
                    variable_name: "Applicant ID",
                    value: formData.EntryID
                },
                {
                    display_name: "Application ID",
                    variable_name: "Application ID",
                    value: formData.ApplicationID
                },
                {
                    display_name: "Applicant Name",
                    variable_name: "Applicant Name",
                    value: `${formData.FirstName} ${formData.MiddleName} ${formData.Surname}`
                },
                {
                    display_name: "Course ID",
                    variable_name: "Course ID",
                    value: formData.CourseID
                },
                {
                    display_name: "Payment For",
                    variable_name: "Payment For",
                    value: "Short Course"
                },
            ]
        }
    };


    const getData = async () => {
        await axios.get(`${serverLink}short/course/admission-letter/${ApplicationID}`).then((res) => {
            if (res.data.length > 0) {
                setFormData({
                    ...formData,
                    EntryID: res.data[0]?.EntryID,
                    FirstName: res.data[0]?.FirstName,
                    MiddleName: res.data[0]?.MiddleName,
                    Surname: res.data[0]?.Surname,
                    EmailAddress: res.data[0]?.EmailAddress,
                    PhoneNumber: res.data[0]?.PhoneNumber,
                    Organisation: res.data[0]?.Organisation,
                    HighestQualification: res.data[0]?.HighestQualification,
                    AreaOfTraining: res.data[0]?.AreaOfTraining,
                    EmploymentStatus: res.data[0]?.EmploymentStatus,
                    CourseID: res.data[0]?.CourseID,
                    CourseName : res.data[0]?.CourseName,
                    JobLevel: res.data[0]?.JobLevel,
                    Cohort: res.data[0]?.Cohort,
                    CohortCode : res.data[0]?.CohortCode,
                    CohortName: res.data[0]?.CohortName,
                    Status: res.data[0]?.Status,
                    DepartmentID: res.data[0]?.DepartmentID,
                    DurationType: res.data[0]?.DurationType,
                    ModeOfTeaching: res.data[0]?.ModeOfTeaching,
                    Price: res.data[0]?.Price,
                    ApplicationID: res.data[0]?.ApplicationID,
                })
                setData(res.data)
            } else {
                window.location.href = "/"
            }
        }).catch((e) => {
            console.log("network error")
        })
    }

    useEffect(() => {
        getData()
    }, [ApplicationID])

    const printAdmission = () => {
        setShowAdmissionLetter(true);
        setTimeout(() => {
            handlePrint();
            setShowAdmissionLetter(false);
        }, 300);
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    const onSuccess = (reference) => {
        handlePostPayment(reference)
    };

    const onClose = () => {
        console.log('closed')
    }

    const initializePayment = usePaystackPayment(config);

    const paymentInitialize = () => {
        if (formData.Price.toString().trim() === "0") {
            showAlert("EMPTY FIELD", "Amount form field cannot be empty", "error");
            return false;
        }
        initializePayment(onSuccess, onClose)
    }

    const handlePostPayment = async (reference) => {
        try {
            let sendData = {
                ...formData,
                Reference: reference.trxref,
                PaymentMethod: "Paystack",
                TransactionType: "Certificate Verification",
            };
            await axios.post(`${serverLink}short/course/enrolment`, sendData) .then(async (res) => {
                if (res.data.message === "success"){
                    const body = `
                <div style="font-family:Arial,Tahoma;font-size:15px">
                        <h4 style="text-align:center;">
                            <img src="https://portal.bazeuniversity.edu.ng/assets/img/logo.png" style="width:90px" alt=""><br>
                            <h4 style="text-align:center;">${"reg_date"}</h4>
                        </h4>
                        <h4 style="text-align:center;font-size:18px;margin:5px">
                           PAYMENT NOTIFICATION
                        </h4>
                             
                    <p style="text-align:justify;">Dear ${formData.FirstName},</p>
                    <p>We have received your payment for. You will receive an email letting you know the outcome of your enrolment.</p>
                    <br/>
                    <hr>
                    <p>This email is intended for ${formData.FirstName} ${formData.MiddleName} ${formData.Surname}.</p>
                    <hr>
                        <p>
                            For more Information, contact the<strong> Admission Team</strong> (<a href="mailto:itri@bazeuniversity.edu.ng">itri@bazeuniversity.edu.ng</a>)
                        </p>
                        <p>
                            The information transmitted is intended only for the person or entity to which it is addressed and may contain confidential and or privileged material. If you receive this mail in error, please contact the sender and delete this material
                            immediately.</p>
                  <hr/>
                  </div>
    `;
                    const sendEmailData = {
                        logo: projectLogo,
                        from: 'itri@bazeuniversity.edu.ng',
                        to: "ahmad.idris@bazeuniversity.edu.ng",
                        name: `${formData.FirstName} ${formData.MiddleName} ${formData.Surname}`,
                        app_id: "",
                        body: body,
                    }
                    await axios.post(`${serverLink}send_email/send`, sendEmailData).then(r => { })
                    showAlert('PAYMENT SUCCESSFUL', 'We have received your payment for. You will receive an email letting you know the outcome of your enrolment.', 'success');
                }else{
                    showAlert("ERROR", "Something went wrong completing the transaction. Please send the payment receipt to itri@bazeuniversity.edu.ng", "error");
                }

            })
        } catch (e) {
            showAlert("ERROR", "NETWORK ERROR", "error");
        }
    }


    return (
        <div>
            <div className="pcoded-inner-content" style={{ fontFamily: 'monospace', fontSize: '15px' }}>
                <div className="main-body">
                    <div className="page-wrapper">
                        <div className="page-body">
                            <div className="row">

                                <div className="col-sm-12">
                                    <div className="row justify-content-md-center" style={{ textAlign: 'center' }}>
                                        <div className="col-md-10 mt-20">
                                            <h1 className="wow fadeInUp" data-wow-delay="0s" style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInUp' }}>Congratulations</h1>
                                            <span className="wow fadeInUp" data-wow-delay="0.2s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: 'fadeInUp', fontSize: '20px' }}>
                                                <h6>Download your admission letter</h6>
                                            </span>


                                        </div>
                                        <div className="col-md-12">
                                            <div className="hero-image">
                                                <img className="img-fluid" src="" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card m-5 p-5">
                                        <div className="card-block p-10 ">
                                            <div>
                                                <blockquote className="blockquote">
                                                    <h4 style={{ fontSize: '15px' }} className="sub-title">Registration Guidelines</h4>
                                                    <p style={{ fontSize: '15px' }}>
                                                        Follow these steps to register:
                                                    </p>
                                                </blockquote>
                                            </div>

                                            <hr />
                                            <div className="row" style={{ textAlign: 'left' }}>
                                                <div className="col-md-6">
                                                    <div className="col-sm-12 col-xs-12">

                                                        <ol >
                                                            <li> Download Admission Letter and other supporting documents</li>
                                                            <li>Visit our online payment system at <a style={{ color: 'blue', textDecoration: 'underline' }} href="payment.bazeuniversity.edu.ng" target={"_blank"} >payment.bazeuniversity.edu.ng</a></li>
                                                            <li>Proceed to the registry for documents verification</li>
                                                            <li>Proceed to Block A, room 23 for biometrics and ID card</li>
                                                            <li>Login to your portal and download your lecture timetable</li>
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid" style={{ maxWidth: "95rem" }}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="card p-3  mt-2 mb-3">
                                                        <div className="card-block">
                                                            <h5>Admission Letter</h5>
                                                            {
                                                                data.length > 0 &&
                                                                <button className="btn btn-md btn-secondary w-100" onClick={printAdmission} >Click here to download Admission Letter</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="card p-3 mt-2 mb-3">
                                                        <div className="card-block">
                                                            <h5>Payment/Enrolment</h5>
                                                            <button className="btn btn-md btn-secondary w-100" onClick={paymentInitialize} >Click here to make payment</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    showAdmissionLetter === true &&
                                    <LetterTemplate componentRef={componentRef} data={data} />

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const LetterTemplate = (props) => {
    return (
        <div>
            {
                props.data.length > 0 &&
                <div style={{
                    backgroundImage: `url(${BgImage})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }}>
                    <div className="bg body" style={{ paddingBottom: '5px' }} ref={props.componentRef} >
                        <div className="table-wrapper" >
                            <div>
                                <br />
                                <p className='p-text' >
                                    <strong>Application ID:</strong> APP/{props.data[0]?.CohortCode}/{props.data[0]?.EntryID}
                                    <br />
                                    <strong>Applicant Name:</strong> {props.data[0]?.FirstName + " " + props.data[0]?.MiddleName + " " + props.data[0]?.Surname}
                                    <br />
                                    {props.data[0]?.Organisation}
                                    <br />
                                </p>


                                <p>Dear {props.data[0]?.FirstName?.trim()},</p>
                            </div>
                            <br /><br />
                            <div className="title"> OFFER OF PROVISIONAL ADMISSION </div>
                            <div style={{ marginTop: "-5px" }} >
                                <div>I am pleased to notify you that you have been offered a place in our Professional Certificate Program - <strong>{props.data[0]?.CourseName} </strong> for <strong> {props.data[0]?.CohortName}</strong> due to commence teaching on the <strong>{formatDateAndTime(props.data[0]?.StartDate, "date")}.</strong>
                                </div>
                                <br />
                                <div>
                                    Upon further review of your portfolio, career statement, interests and goals, we are excited to offer you a place in this program. We believe this program will be of immense benefit with all the experiences and industry exposure it has to offer.
                                </div>
                                <div>
                                    To secure your place, you are advised to make payment of your program fee of <strong>{convertNumbertoWords(props.data[0]?.Price)} ({currencyConverter(props.data[0]?.Price)}) </strong> or pay 50% of the program fee <strong>({currencyConverter(parseFloat(props.data[0]?.Price * 0.5))})</strong> to secure your place using the payment options provided in Appendix 2.
                                </div>
                                <div>
                                    Please review the next steps specified in Appendix 1 and 2 of this document to complete your enrolment into the program. Kindly note that the University reserves the right to disengage you from this program if you do not meet all the necessary criteria for enrolment. If necessary, Baze University also reserves the right to amend previously announced fees.
                                </div>
                                <br />
                                <div>
                                    We hope that you will be inspired to make a meaningful impact with all you will learn during this program.
                                </div>

                                <br /><br />
                                <div>Congratulations and welcome to Baze University!</div>
                                <div>Warm Regards,</div>

                                <img src={VCSign} width="180px" alt="vc sign" />
                                <br />
                                <strong>Professor Tahir Mamman, OON, SAN.</strong>
                                <br />
                                Vice-Chancellor
                            </div>
                            <br /><br />



                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
export default AdmissionLetter