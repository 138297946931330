export const setHomePageData = (p)=>{
    return{
        type: "SET_HOMEPAGE_DATA",
        payload: p
    };
};
export const setHomeReport = (p)=>{
    return{
        type: "SET_HOME_REPORT_DATA",
        payload: p
    };
};
// export const setDepartmentList = (p)=>{
//     return{
//         type: "SET_DEPARTMENT_LIST_DATA",
//         payload: p
//     };
// };
// export const setModuleList = (p)=>{
//     return{
//         type: "SET_MODULE_LIST_DATA",
//         payload: p
//     };
// };
// export const setTutorList = (p)=>{
//     return{
//         type: "SET_TUTOR_LIST_DATA",
//         payload: p
//     };
// };
// export const setTutorEnrolment = (p)=>{
//     return{
//         type: "SET_TUTOR_ENROLMENT_DATA",
//         payload: p
//     };
// };



