import React from "react";
import {Link} from "react-router-dom";
import crumb from "../../../images/breadcrumb-bg-1.jpg";

const Breadcrumb = (props) => {
    return <section className="breadcrumb__area include-bg pt-150 pb-150 breadcrumb__overlay" style={{backgroundImage: `url(${crumb})`}}>
        <div className="container">
            <div className="row">
                <div className="col-xxl-12">
                    <div className="breadcrumb__content text-center p-relative z-index-1">
                        <h3 className="breadcrumb__title">{props.page_name}</h3>
                        <div className="breadcrumb__list">
                            <span><Link to={"/"}>Home</Link></span>
                            <span className="dvdr"><i className="fa-regular fa-angle-right"></i></span>
                            <span>{props.page_name}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}

export default Breadcrumb;
